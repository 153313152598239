import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/en/HomeEn.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/about/en",
    name: "About",
    component: () => import("./views/en/AboutView.vue"),
    meta: {
      testimoniosusuaiosEn: true,
      menuEs: true,
      contactformEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/Blog/en",
    name: "Blog",
    component: () => import("./views/en/BlogView.vue"),
    meta: {
      testimoniosusuaiosEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service",
    name: "Service",
    component: HomeView,
    meta: {
      title: "PSOFNTECH - HOME",
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/sub/LaptopRepair/:rout/en",
    name: "Laptop Repair",
    component: () => import("./views/en/LaptopRepair.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/DigitalMarketing/:rout/en",
    name: "Digital Marketing",
    component: () => import("./views/en/DigitalMarketing.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/OnsiteComputerRepair/en",
    name: "OnsiteComputerRepair",
    component: () => import("./views/en/OnsiteComputerRepair.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      contactformEn: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/WebDesign/en",
    name: "Web Design",
    component: () => import("./views/en/WebDesing.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/AppDevelopment/en",
    name: "App Development",
    component: () => import("./views/en/AppDevelopment.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/MerchantServices/en",
    name: "Merchant Services",
    component: () => import("./views/en/MerchantServices.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      contactformEn: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/:type/en",
    name: "Services",
    component: () => import("./views/en/ServiceView.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      contactformEn: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/service/sub/GraphicDesign/:rout/en",
    name: "Service Graphic Design",
    component: () => import("./views/en/SubGraphics.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/:type/en",
    name: "Cards",
    component: () => import("./views/en/WithCards.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
    },
  },
  {
    path: "/service/sub/:type/:rout/en",
    name: "Ruta1",
    component: () => import("./views/en/SubServices.vue"),
    meta: {
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
    },
  },
  /*----------------PARTE Spanish--------------------------------------- */
  {
    path: "/about/es",
    name: "About Es",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/resource/es",
    name: "Resource Es",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/Blog/es",
    name: "EsBlog",
    component: () => import("./views/es/BlogViewEs.vue"),
    meta: {
      layout: "LayoutEs",
      testimoniosusuaiosEs: true,
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/es",
    name: "EsHome",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service",
    name: "Service Spanish",
    component: () => import("./views/es/HomeEs.vue"),
    meta: {
      layout: "LayoutEs",
      title: "PSOFNTECH - INICIO",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/service/sub/:type/es",
    name: ":type",
    component: () => import("./views/es/WithCardsEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
    },
  },
  {
    path: "/service/OnsiteComputerRepair/es",
    name: "OnsiteComputerRepairEs",
    component: () => import("./views/es/OnsiteComputerRepairEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      contactformEs: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/WebDesign/es",
    name: "WebDesign2ES",
    component: () => import("./views/es/WebDesingEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/AppDevelopment/es",
    name: "App2ES",
    component: () => import("./views/es/AppDevelopmentEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/MerchantServices/es",
    name: "MerchaES",
    component: () => import("./views/es/MerchantServicesEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      contactformEs: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/:type/es",
    name: "SimpleES",
    component: () => import("./views/es/ServiceViewEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      contactformEs: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/service/sub/GraphicDesign/:rout/es",
    name: "RutaES",
    component: () => import("./views/es/SubGraphicsEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
    },
  },

  {
    path: "/service/sub/:type/:rout/es",
    name: "Ruta1Es",
    component: () => import("./views/es/SubServicesEs.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
    },
  },

  /*----------------PARTE BACKK--------------------------------------- */
  {
    path: "/login",
    name: "login",
    component: () => import("./views/CMS/LoginView.vue"),
    meta: {
      layout: "LoginLayout",
      title: "PSOFNTECH - LOGIN",
    },
  },
  {
    path: "/users",
    name: "User",
    component: () => import("./views/CMS/UsersView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - USERS",
    },
  },
  {
    path: "/pages",
    name: "Pages CMS",
    component: () => import("./views/CMS/PagesView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },

  {
    path: "/pages/:id",
    name: "Page",
    component: () => import("./views/CMS/PageView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PAGE",
    },
  },

  {
    path: "/testimonials",
    name: "Testimonials",
    component: () => import("./views/CMS/Testimonial/TestimonialView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMOANIALS",
    },
  },

  {
    path: "/testimonial-create",
    name: "Create Testimonial",
    component: () => import("./views/CMS/Testimonial/components/CreateComponent.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMONIAL"
    }
  },

  {
    path: "/testimonial-edit/:id",
    name: "Update Testimonial",
    component: () => import("./views/CMS/Testimonial/components/EditComponent.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - TESTIMONIAL"
    }
  },

  {
    path: '/testimonial/:token',
    name: 'testimonial-form',
    component: () => import("./views/en/TestimonialForm.vue"),
    meta: {
      title: "PSOFNTECH - TESTIMONIAL"
    },
  },

  {
    path: "/site",
    name: "Site",
    component: () => import("./views/CMS/SiteView/ContactInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONTACT",
    },
  },
  {
    path: "/site/SEO",
    name: "Site-SEO",
    component: () => import("./views/CMS/SiteView/SeoInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - SEO",
    },
  },
  {
    path: "/site/general-configuration",
    name: "Configuration",
    component: () => import("./views/CMS/SiteView/GeneralConfig.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONFIGURATION",
    },
  },
  {
    path: "/site/Contact",
    name: "Contact",
    component: () => import("./views/CMS/SiteView/ContactInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - CONTACT",
    },
  },
  {
    path: "/site/Banner",
    name: "Banner",
    component: () => import("./views/CMS/SiteView/BannerIndex.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BANNER",
    },
  },
  {
    path: "/site/BannerSubServicios",
    name: "Parallax",
    component: () => import("./views/CMS/SiteView/BannerSubServicios.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BANNER",
    },
  },
  {
    path: "/site/Business",
    name: "Business",
    component: () => import("./views/CMS/SiteView/BusinessInfo.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - BUSINESS",
    },
  },
  {
    path: "/portfolio",
    name: "Portafolios",
    component: () => import("./views/CMS/Portfolio/PortfolioTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PAGES",
    },
  },
  {
    path: "/portfolio/:rout",
    name: "Portfolios",
    component: () => import("./views/CMS/Portfolio/PortfolioTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIOS",
    },
  },
  {
    path: "/portfolio/:rout/:id",
    name: "Portfolio",
    component: () => import("./views/CMS/Portfolio/PortfolioView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIOS",
    },
  },
  {
    path: "/blogCMS",
    name: "Blogs CMS",
    component: () => import("./views/CMS/Blog/Blogs.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/blogCMS/:id",
    name: "Blog CMS",
    component: () => import("./views/CMS/Blog/Blog.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/aboutCMS",
    name: "About US CMS",
    component: () => import("./views/CMS/About/AboutUS.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - ABOUT US",
    },
  },
  {
    path: "/servicescms",
    name: "Services CMS",
    component: () => import("./views/CMS/Servicios/PackagesTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/Package",
    name: "Package",
    component: () => import("./views/CMS/Servicios/PackagesTable.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/Package/:id",
    name: "Package Edit",
    component: () => import("./views/CMS/Servicios/PackageView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/servicescms/services",
    name: "All Services",
    component: () => import("./views/CMS/Servicios/ServiciosView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - PORTFOLIO",
    },
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("./views/CMS/Menu/MenuView.vue"),
    meta: {
      layout: "DashboardView",
      title: "PSOFNTECH - MENU",
    },
  },


  /* added as of 10/18/22 */

  {
    path: "/webdesign/ny/ridgewood/queens/en",
    name: "Web Design | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingRIDGEWOOD-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ridgewood/queens/es",
    name: "Diseño Web | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingRIDGEWOOD-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/glendale/queens/en",
    name: "Web Design | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingGLENDALE-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/glendale/queens/es",
    name: "Diseño Web | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingGLENDALE-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/webdesign/ny/maspeth/queens/en",
    name: "Web Design | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingMASPETH-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/maspeth/queens/es",
    name: "Diseño Web | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingMASPETH-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/laurel-hill/queens/en",
    name: "Web Design | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingLaurelHill-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/laurel-hill/queens/es",
    name: "Diseño Web | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingLaurelHill-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/middle-village/queens/en",
    name: "Web Design | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingMiddleVillage-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/middle-village/queens/es",
    name: "Diseño Web | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingMiddleVillage-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/blissville/queens/en",
    name: "Web Design | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingBlissville-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/blissville/queens/es",
    name: "Diseño Web | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingBlissville-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/corona/queens/en",
    name: "Web Design | New York (NY) | Corona, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingCorona-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/corona/queens/es",
    name: "Diseño Web | New York (NY) | Corona, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingCorona-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/east-elmhurst/queens/en",
    name: "Web Design | New York (NY) | East Elmhurst, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingEastElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/east-elmhurst/queens/es",
    name: "Diseño Web | New York (NY) | East Elmhurst, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingEastElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/elmhurst/queens/en",
    name: "Web Design | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/elmhurst/queens/es",
    name: "Diseño Web | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/jackson-heights/queens/en",
    name: "Web Design | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingJacksonHeights-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/jackson-heights/queens/es",
    name: "Diseño Web | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingJacksonHeights-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/woodside/queens/en",
    name: "Web Design | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingWoodside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/woodside/queens/es",
    name: "Diseño Web | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingWoodside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/sunnysude-gardens/queens/en",
    name: "Web Design | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingSunnysudeGardens-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/sunnysude-gardens/queens/es",
    name: "Diseño Web | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingSunnysudeGardens-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/sunnyside/queens/en",
    name: "Web Design | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingSunnyside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/sunnyside/queens/es",
    name: "Diseño Web | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingSunnyside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/long-island-city/queens/en",
    name: "Web Design | New York (NY) | Long Island City, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingLongIslandCity-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/long-island-city/queens/es",
    name: "Diseño Web | New York (NY) | Long Island City, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingLongIslandCity-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hunters-point/queens/en",
    name: "Web Design | New York (NY) | Hunters Point, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingHuntersPoint-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hunters-point/queens/es",
    name: "Diseño Web | New York (NY) | Hunters Point, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingHuntersPoint-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/astoria/queens/en",
    name: "Web Design | New York (NY) | Astoria, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingAstoria-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/astoria/queens/es",
    name: "Diseño Web | New York (NY) | Astoria, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingAstoria-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/steinway/queens/en",
    name: "Web Design | New York (NY) | Steinway, QUEENS",
    component: () => import("./views/en/address/queens/WebDesingSteinway-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/steinway/queens/es",
    name: "Diseño Web | New York (NY) | Steinway, QUEENS",
    component: () => import("./views/es/address/queens/WebDesingSteinway-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },


  // routes BRONX

   {
    path: "/webdesign/ny/riverdale/bronx/en",
    name: "Web Design | New York (NY) | Steinway, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingRiverdale-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/riverdale/bronx/es",
    name: "Diseño Web | New York (NY) | Steinway, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingRiverdale-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/kingsbridge/bronx/en",
    name: "Web Design | New York (NY) | Kingsbridge, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingKingsbridge-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/kingsbridge/bronx/es",
    name: "Diseño Web | New York (NY) | Kingsbridge, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingKingsbridge-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/berdford-park/bronx/en",
    name: "Web Design | New York (NY) | Berdford Park, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingBerdfordPark-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/berdford-park/bronx/es",
    name: "Diseño Web | New York (NY) | Berdford Park, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingBerdfordPark-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/norwood/bronx/en",
    name: "Web Design | New York (NY) | Norwood, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingNorwood-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/norwood/bronx/es",
    name: "Diseño Web | New York (NY) | Norwood, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingNorwood-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/university-heights/bronx/en",
    name: "Web Design | New York (NY) | University heights, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingUniversityheights-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/university-heights/bronx/es",
    name: "Diseño Web | New York (NY) | University heights, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingUniversityheights-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

   {
    path: "/webdesign/ny/morris-heights/bronx/en",
    name: "Web Design | New York (NY) | Morris Heights, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingMorrisHeights-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/morris-heights/bronx/es",
    name: "Diseño Web | New York (NY) | Morris Heights, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingMorrisHeights-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fordham/bronx/en",
    name: "Web Design | New York (NY) | Fordham, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingFordham-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fordham/bronx/es",
    name: "Diseño Web | New York (NY) | Fordham, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingFordham-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/parkchester/bronx/en",
    name: "Web Design | New York (NY) | Parkchester, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingParkchester-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/parkchester/bronx/es",
    name: "Diseño Web | New York (NY) | Parkchester, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingParkchester-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/van-cortland-park/bronx/en",
    name: "Web Design | New York (NY) | Van Cortland Park, BRONX",
    component: () => import("./views/en/address/bronx/WebDesingVanCortlandPark-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/van-cortland-park/bronx/es",
    name: "Diseño Web | New York (NY) | Van Cortland Park, BRONX",
    component: () => import("./views/es/address/bronx/WebDesingVanCortlandPark-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },


  // routes Manhattan

  {
    path: "/webdesign/ny/washington-heights/manhattan/en",
    name: "Web Design | New York (NY) | Van Cortland Park, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingWashingtonHeights-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/washington-heights/manhattan/es",
    name: "Diseño Web | New York (NY) | Van Cortland Park, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingWashingtonHeights-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/inwood/manhattan/en",
    name: "Web Design | New York (NY) | Inwood, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingInwood-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/inwood/manhattan/es",
    name: "Diseño Web | New York (NY) | Inwood, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingInwood-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fort-george/manhattan/en",
    name: "Web Design | New York (NY) | Fort George, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingFortGeorge-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fort-george/manhattan/es",
    name: "Diseño Web | New York (NY) | Fort George, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingFortGeorge-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/harlem/manhattan/en",
    name: "Web Design | New York (NY) | Harlem, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingHarlem-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/harlem/manhattan/es",
    name: "Diseño Web | New York (NY) | Harlem, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingHarlem-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/morninng-heights/manhattan/en",
    name: "Web Design | New York (NY) | Morninng Heights, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingMorninngHeights-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/morninng-heights/manhattan/es",
    name: "Diseño Web | New York (NY) | Morninng Heights, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingMorninngHeights-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/upper-west-side/manhattan/en",
    name: "Web Design | New York (NY) | Upper West Side, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingUpperWestSide-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/upper-west-side/manhattan/es",
    name: "Diseño Web | New York (NY) | Upper West Side, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingUpperWestSide-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/midtown-west/manhattan/en",
    name: "Web Design | New York (NY) | Midtown West, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingMidtownWest-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/midtown-west/manhattan/es",
    name: "Diseño Web | New York (NY) | Midtown West, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingMidtownWest-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  // {
  //   path: "/webdesign/ny/midtown-east/manhattan/en",
  //   name: "Web Design | New York (NY) | Midtown East, Manhattan",
  //   component: () => import("./views/en/address/manhattan/WebDesingMidtownEast-Manhattan.vue"),
  //   meta: {
  //     contactformEn: true,
  //     menuEs: true,
  //     contactformEs: true,
  //     testimoniosusuaiosEs: true,
  //     piepaginaEs: true,
  //   },
  // },
  // {
  //   path: "/webdesign/ny/midtown-east/manhattan/es",
  //   name: "Diseño Web | New York (NY) | Midtown East, Manhattan",
  //   component: () => import("./views/es/address/manhattan/WebDesingMidtownEast-Manhattan-es.vue"),
  //   meta: {
  //     layout: "LayoutEs",
  //     menuEn: true,
  //     contactformEn: true,
  //     testimoniosusuaiosEn: true,
  //     piepaginaEn: true,
  //   },
  // },

  {
    path: "/webdesign/ny/garden-district/manhattan/en",
    name: "Web Design | New York (NY) | Garden District, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingGardenDistrict-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/garden-district/manhattan/es",
    name: "Diseño Web | New York (NY) | Garden District, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingGardenDistrict-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/murray-hill/manhattan/en",
    name: "Web Design | New York (NY) | Murray Hill, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingMurrayHill-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/murray-hill/manhattan/es",
    name: "Diseño Web | New York (NY) | Murray Hill, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingMurrayHill-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/west-village/manhattan/en",
    name: "Web Design | New York (NY) | West Village, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingWestVillage-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/west-village/manhattan/es",
    name: "Diseño Web | New York (NY) | West Village, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingWestVillage-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/soho/manhattan/en",
    name: "Web Design | New York (NY) | Soho, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingSoho-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/soho/manhattan/es",
    name: "Diseño Web | New York (NY) | Soho, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingSoho-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/lower-east-side/manhattan/en",
    name: "Web Design | New York (NY) | Lower East Side, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingLowerEastSide-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/lower-east-side/manhattan/es",
    name: "Diseño Web | New York (NY) | Lower East Side, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingLowerEastSide-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/tribeca/manhattan/en",
    name: "Web Design | New York (NY) | Tribeca, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingTribeca-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/tribeca/manhattan/es",
    name: "Diseño Web | New York (NY) | Tribeca, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingTribeca-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/financial-district/manhattan/en",
    name: "Web Design | New York (NY) | Financial District, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingFinancialDistrict-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/financial-district/manhattan/es",
    name: "Diseño Web | New York (NY) | Financial District, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingFinancialDistrict-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/chinatown/manhattan/en",
    name: "Web Design | New York (NY) | Chinatown, Manhattan",
    component: () => import("./views/en/address/manhattan/WebDesingChinatown-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/chinatown/manhattan/es",
    name: "Diseño Web | New York (NY) | Chinatown, Manhattan",
    component: () => import("./views/es/address/manhattan/WebDesingChinatown-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  
  /* / routes new jersey */

  {
    path: "/webdesign/ny/bergenfield/new-jersey/en",
    name: "Web Design | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingBergenfield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bergenfield/new-jersey/es",
    name: "Diseño Web | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingBergenfield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/webdesign/ny/allendale/new-jersey/en",
    name: "Web Design | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingAllendale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/allendale/new-jersey/es",
    name: "Diseño Web | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingAllendale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/alpine/new-jersey/en",
    name: "Web Design | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingAlpine-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/alpine/new-jersey/es",
    name: "Diseño Web | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingAlpine-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/bogota/new-jersey/en",
    name: "Web Design | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingBogota-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bogota/new-jersey/es",
    name: "Diseño Web | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingBogota-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/caristadt/new-jersey/en",
    name: "Web Design | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCaristadt-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/caristadt/new-jersey/es",
    name: "Diseño Web | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCaristadt-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/cliffside-park/new-jersey/en",
    name: "Web Design | New York (NY) | Cliffside Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/cliffside-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Cliffside Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCliffside-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/park-closter/new-jersey/en",
    name: "Web Design | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingParkCloster-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/park-closter/new-jersey/es",
    name: "Diseño Web | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingParkCloster-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/cresskill/new-jersey/en",
    name: "Web Design | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/cresskill/new-jersey/es",
    name: "Diseño Web | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingCresskill-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/demarest/new-jersey/en",
    name: "Web Design | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingDemarest-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/demarest/new-jersey/es",
    name: "Diseño Web | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingDemarest-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/dumont/new-jersey/en",
    name: "Web Design | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingDumont-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/dumont/new-jersey/es",
    name: "Diseño Web | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingDumont-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/east-rutherford/new-jersey/en",
    name: "Web Design | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEastRutherford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/east-rutherford/new-jersey/es",
    name: "Diseño Web | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEastRutherford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/edgewater/new-jersey/en",
    name: "Web Design | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEdgewater-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/edgewater/new-jersey/es",
    name: "Diseño Web | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEdgewater-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/elmwood-park/new-jersey/en",
    name: "Web Design | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingElmwoodPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/elmwood-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingElmwoodPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/emerson/new-jersey/en",
    name: "Web Design | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEmerson-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/emerson/new-jersey/es",
    name: "Diseño Web | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEmerson-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/englewood/new-jersey/en",
    name: "Web Design | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEnglewood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/englewood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEnglewood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/paramus/new-jersey/en",
    name: "Web Design | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingParamus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/paramus/new-jersey/es",
    name: "Diseño Web | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingParamus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/teterboro/new-jersey/en",
    name: "Web Design | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingTeterboro-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/teterboro/new-jersey/es",
    name: "Diseño Web | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingTeterboro-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/moonachie/new-jersey/en",
    name: "Web Design | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingMoonachie-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/moonachie/new-jersey/es",
    name: "Diseño Web | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingMoonachie-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/kort-lee/new-jersey/en",
    name: "Web Design | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingFortLee-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/kort-lee/new-jersey/es",
    name: "Diseño Web | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingFortLee-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/lodi/new-jersey/en",
    name: "Web Design | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingLodi-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/lodi/new-jersey/es",
    name: "Diseño Web | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingLodi-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/little-ferry/new-jersey/en",
    name: "Web Design | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingLittleFerry-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/little-ferry/new-jersey/es",
    name: "Diseño Web | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingLittleFerry-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hackensack/new-jersey/en",
    name: "Web Design | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHackensack-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hackensack/new-jersey/es",
    name: "Diseño Web | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHackensack-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/englewood-cliffs/new-jersey/en",
    name: "Web Design | New York (NY) | Englewood Cliffs, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingEnglewoodCliffs-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/englewood-cliffs/new-jersey/es",
    name: "Diseño Web | New York (NY) | Englewood Cliffs, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingEnglewoodCliffs-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/norwood/new-jersey/en",
    name: "Web Design | New York (NY) | Norwood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingNorwood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/norwood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Norwood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingNorwood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fair-lawn/new-jersey/en",
    name: "Web Design | New York (NY) | Fair Lawn, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingFairLawn-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fair-lawn/new-jersey/es",
    name: "Diseño Web | New York (NY) | Fair Lawn, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingFairLawn-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fairview/new-jersey/en",
    name: "Web Design | New York (NY) | Fairview, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingFairview-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fairview/new-jersey/es",
    name: "Diseño Web | New York (NY) | Fairview, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingFairview-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/franklin-lakes/new-jersey/en",
    name: "Web Design | New York (NY) | Franklin Lakes, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingFranklinLakes-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/franklin-lakes/new-jersey/es",
    name: "Diseño Web | New York (NY) | Franklin Lakes, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingFranklinLakes-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/garfield/new-jersey/en",
    name: "Web Design | New York (NY) | Garfield, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingGarfield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/garfield/new-jersey/es",
    name: "Diseño Web | New York (NY) | Garfield, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingGarfield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/glen-rock/new-jersey/en",
    name: "Web Design | New York (NY) | Glen Rock, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingGlenRock-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/glen-rock/new-jersey/es",
    name: "Diseño Web | New York (NY) | Glen Rock, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingGlenRock-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/harrington-park/new-jersey/en",
    name: "Web Design | New York (NY) | Harrington Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHarringtonPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/harrington-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Harrington Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHarringtonPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hasbrouck-heights/new-jersey/en",
    name: "Web Design | New York (NY) | Hasbrouck Heights, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHasbrouckHeights-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hasbrouck-heights/new-jersey/es",
    name: "Diseño Web | New York (NY) | Hasbrouck Heights, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHasbrouckHeights-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/haworth/new-jersey/en",
    name: "Web Design | New York (NY) | Haworth, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHaworth-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/haworth/new-jersey/es",
    name: "Diseño Web | New York (NY) | Haworth, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHaworth-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hawthorne/new-jersey/en",
    name: "Web Design | New York (NY) | hawthorne, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHawthorne-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hawthorne/new-jersey/es",
    name: "Diseño Web | New York (NY) | Hawthorne, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHawthorne-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/hillsdale/new-jersey/en",
    name: "Web Design | New York (NY) | Hillsdale, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHillsdale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/hillsdale/new-jersey/es",
    name: "Diseño Web | New York (NY) | Hillsdale, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHillsdale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/ho-ho-kus/new-jersey/en",
    name: "Web Design | New York (NY) | Ho-Ho-Kus, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingHoHoKus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ho-ho-kus/new-jersey/es",
    name: "Diseño Web | New York (NY) | Ho-Ho-Kus, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingHoHoKus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/leonia/new-jersey/en",
    name: "Web Design | New York (NY) | Leonia, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingLeonia-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/leonia/new-jersey/es",
    name: "Diseño Web | New York (NY) | Leonia, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingLeonia-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/maywood/new-jersey/en",
    name: "Web Design | New York (NY) | Maywood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingMaywood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/maywood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Maywood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingMaywood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/midland-park/new-jersey/en",
    name: "Web Design | New York (NY) | Midland Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingMidlandPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/midland-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Midland Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingMidlandPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  
  {
    path: "/webdesign/ny/montvale/new-jersey/en",
    name: "Web Design | New York (NY) | Montvale, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingMontvale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/montvale/new-jersey/es",
    name: "Diseño Web | New York (NY) | Montvale, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingMontvale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/new-mildford/new-jersey/en",
    name: "Web Design | New York (NY) | New Mildford, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingNewMildford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/new-mildford/new-jersey/es",
    name: "Diseño Web | New York (NY) | New Mildford, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingNewMildford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/north-arlinton/new-jersey/en",
    name: "Web Design | New York (NY) | North Arlington, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingNorthArlinton-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/north-arlinton/new-jersey/es",
    name: "Diseño Web | New York (NY) | North Arlington, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingNorthArlinton-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/northvale/new-jersey/en",
    name: "Web Design | New York (NY) | Northvale, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingNorthvale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/northvale/new-jersey/es",
    name: "Diseño Web | New York (NY) | Northvale, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingNorthvale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/oakland/new-jersey/en",
    name: "Web Design | New York (NY) | Oakland, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingOakland-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/oakland/new-jersey/es",
    name: "Diseño Web | New York (NY) | Oakland, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingOakland-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/old-tappan/new-jersey/en",
    name: "Web Design | New York (NY) | Old Tappan, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingOldTappan-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/old-tappan/new-jersey/es",
    name: "Diseño Web | New York (NY) | Old Tappan, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingOldTappan-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/oradell/new-jersey/en",
    name: "Web Design | New York (NY) | Oradell, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingOradell-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/oradell/new-jersey/es",
    name: "Diseño Web | New York (NY) | Oradell, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingOradell-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/palisades-park/new-jersey/en",
    name: "Web Design | New York (NY) | Palisades Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingPalisadesPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/palisades-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Palisades Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingPalisadesPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/park-ridge/new-jersey/en",
    name: "Web Design | New York (NY) | Park Ridge, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingParkRidge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/park-ridge/new-jersey/es",
    name: "Diseño Web | New York (NY) | Park Ridge, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingParkRidge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/ramsey/new-jersey/en",
    name: "Web Design | New York (NY) | Ramsey, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRamsey-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ramsey/new-jersey/es",
    name: "Diseño Web | New York (NY) | Ramsey, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRamsey-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/ridgefield/new-jersey/en",
    name: "Web Design | New York (NY) | Ridgefield, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRidgefield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ridgefield/new-jersey/es",
    name: "Diseño Web | New York (NY) | Ridgefield, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRidgefield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/ridgefield-park/new-jersey/en",
    name: "Web Design | New York (NY) | Ridgefield Park, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRidgefieldPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ridgefield-park/new-jersey/es",
    name: "Diseño Web | New York (NY) | Ridgefield Park, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRidgefieldPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/ridgewood/new-jersey/en",
    name: "Web Design | New York (NY) | Ridgewood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRidgewood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/ridgewood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Ridgewood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRidgewood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/river-edge/new-jersey/en",
    name: "Web Design | New York (NY) | River Edge, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRiverEdge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/river-edge/new-jersey/es",
    name: "Diseño Web | New York (NY) | River Edge, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRiverEdge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/rockleigh/new-jersey/en",
    name: "Web Design | New York (NY) | Rockleigh, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRockleigh-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/rockleigh/new-jersey/es",
    name: "Diseño Web | New York (NY) | Rockleigh, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRockleigh-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/rutherford/new-jersey/en",
    name: "Web Design | New York (NY) | Rutherford, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingRutherford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/rutherford/new-jersey/es",
    name: "Diseño Web | New York (NY) | Rutherford, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingRutherford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/secaucus/new-jersey/en",
    name: "Web Design | New York (NY) | Secaucus, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingSecaucus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/secaucus/new-jersey/es",
    name: "Diseño Web | New York (NY) | Secaucus, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingSecaucus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/tenafly/new-jersey/en",
    name: "Web Design | New York (NY) | Tenafly, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingTenafly-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/tenafly/new-jersey/es",
    name: "Diseño Web | New York (NY) | Tenafly, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingTenafly-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/upper-saddle-river/new-jersey/en",
    name: "Web Design | New York (NY) | Upper Saddle River, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingUpperSaddleRiver-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/upper-saddle-river/new-jersey/es",
    name: "Diseño Web | New York (NY) | Upper Saddle River, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingUpperSaddleRiver-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/waldwick/new-jersey/en",
    name: "Web Design | New York (NY) | Waldwick, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingWaldwick-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/waldwick/new-jersey/es",
    name: "Diseño Web | New York (NY) | Waldwick, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingWaldwick-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/wallington/new-jersey/en",
    name: "Web Design | New York (NY) | Wallington, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingWallington-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/wallington/new-jersey/es",
    name: "Diseño Web | New York (NY) | Wallington, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingWallington-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/westwood/new-jersey/en",
    name: "Web Design | New York (NY) | Westwood, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingWestwood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/westwood/new-jersey/es",
    name: "Diseño Web | New York (NY) | Westwood, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingWestwood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/wood-ridge/new-jersey/en",
    name: "Web Design | New York (NY) | Wood-Ridge, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingWoodRidge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/wood-ridge/new-jersey/es",
    name: "Diseño Web | New York (NY) | Wood-Ridge, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingWoodRidge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/woodcliff-lake/new-jersey/en",
    name: "Web Design | New York (NY) | Woodcliff Lake, New Jersey",
    component: () => import("./views/en/address/NewJersey/WebDesingWoodcliffLake-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/woodcliff-lake/new-jersey/es",
    name: "Diseño Web | New York (NY) | Woodcliff Lake, New Jersey",
    component: () => import("./views/es/address/NewJersey/WebDesingWoodcliffLake-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  // routes new jersey - Laptop Repair

  {
    path: "/laptop-repair/ny/bergenfield/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairBergenfield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/bergenfield/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Bergenfield, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairBergenfield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/laptop-repair/ny/allendale/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairAllendale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/allendale/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Allendale, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairAllendale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/alpine/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairAlpine-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/alpine/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Alpine, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairAlpine-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/bogota/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairBogota-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/bogota/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Bogota, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairBogota-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/caristadt/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairCaristadt-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/caristadt/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Caristadt, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairCaristadt-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/cliffside-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Cliffside Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/cliffside-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Cliffside Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairCliffside-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/park-closter/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairParkCloster-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/park-closter/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Park Closter, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairParkCloster-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/cresskill/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairCliffside-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/cresskill/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Cresskill, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairCresskill-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/demarest/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairDemarest-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/demarest/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Demarest, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairDemarest-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/dumont/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairDumont-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/dumont/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Dumont, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairDumont-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/east-rutherford/new-jersey/en",
    name: "Laptop Repair | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairEastRutherford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/east-rutherford/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | East Rutherford, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairEastRutherford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/edgewater/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairEdgewater-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/edgewater/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Edgewater, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairEdgewater-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/elmwood-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairElmwoodPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/elmwood-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Elmwood Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairElmwoodPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/emerson/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairEmerson-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/emerson/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Emerson, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairEmerson-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/englewood/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairEnglewood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/englewood/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Englewood, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairEnglewood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/paramus/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairParamus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/paramus/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Paramus, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairParamus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/teterboro/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairTeterboro-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/teterboro/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Teterboro, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairTeterboro-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/moonachie/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairMoonachie-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/moonachie/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Moonachie, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairMoonachie-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/kort-lee/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairFortLee-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/kort-lee/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Fort Lee, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairFortLee-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/lodi/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairLodi-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/lodi/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Lodi, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairLodi-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/little-ferry/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairLittleFerry-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/little-ferry/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Little Ferry, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairLittleFerry-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/hackensack/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHackensack-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/hackensack/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Hackensack, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHackensack-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/englewood-cliffs/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Englewood Cliffs, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairEnglewoodCliffs-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/englewood-cliffs/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Englewood Cliffs, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairEnglewoodCliffs-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/norwood/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Norwood, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairNorwood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/norwood/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Norwood, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairNorwood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/fair-lawn/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Fair Lawn, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairFairLawn-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/fair-lawn/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Fair Lawn, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairFairLawn-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/fairview/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Fairview, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairFairview-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/fairview/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Fairview, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairFairview-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/franklin-lakes/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Franklin Lakes, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairFranklinLakes-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/franklin-lakes/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Franklin Lakes, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairFranklinLakes-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/garfield/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Garfield, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairGarfield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/garfield/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Garfield, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairGarfield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/glen-rock/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Glen Rock, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairGlenRock-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/glen-rock/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Glen Rock, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairGlenRock-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/harrington-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Harrington Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHarringtonPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/harrington-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Harrington Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHarringtonPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/hasbrouck-heights/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Hasbrouck Heights, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHasbrouckHeights-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/hasbrouck-heights/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Hasbrouck Heights, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHasbrouckHeights-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/haworth/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Haworth, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHaworth-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/haworth/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Haworth, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHaworth-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/hawthorne/new-jersey/en",
    name: "Laptop Repair | New York (NY) | hawthorne, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHawthorne-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/hawthorne/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Hawthorne, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHawthorne-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/hillsdale/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Hillsdale, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHillsdale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/hillsdale/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Hillsdale, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHillsdale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/ho-ho-kus/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Ho-Ho-Kus, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairHoHoKus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ho-ho-kus/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Ho-Ho-Kus, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairHoHoKus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/leonia/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Leonia, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairLeonia-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/leonia/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Leonia, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairLeonia-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/maywood/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Maywood, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairMaywood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/maywood/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Maywood, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairMaywood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/midland-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Midland Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairMidlandPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/midland-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Midland Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairMidlandPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  
  {
    path: "/laptop-repair/ny/montvale/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Montvale, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairMontvale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/montvale/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Montvale, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairMontvale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/new-mildford/new-jersey/en",
    name: "Laptop Repair | New York (NY) | New Mildford, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairNewMildford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/new-mildford/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | New Mildford, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairNewMildford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/north-arlinton/new-jersey/en",
    name: "Laptop Repair | New York (NY) | North Arlington, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairNorthArlinton-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/north-arlinton/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | North Arlington, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairNorthArlinton-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/northvale/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Northvale, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairNorthvale-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/northvale/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Northvale, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairNorthvale-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/oakland/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Oakland, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairOakland-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/oakland/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Oakland, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairOakland-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/old-tappan/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Old Tappan, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairOldTappan-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/old-tappan/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Old Tappan, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairOldTappan-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/oradell/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Oradell, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairOradell-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/oradell/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Oradell, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairOradell-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/palisades-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Palisades Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairPalisadesPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/palisades-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Palisades Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairPalisadesPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/park-ridge/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Park Ridge, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairParkRidge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/park-ridge/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Park Ridge, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairParkRidge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/ramsey/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Ramsey, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRamsey-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ramsey/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Ramsey, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRamsey-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/ridgefield/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Ridgefield, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRidgefield-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ridgefield/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Ridgefield, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRidgefield-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/ridgefield-park/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Ridgefield Park, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRidgefieldPark-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ridgefield-park/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Ridgefield Park, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRidgefieldPark-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/ridgewood/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Ridgewood, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRidgewood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ridgewood/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Ridgewood, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRidgewood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/river-edge/new-jersey/en",
    name: "Laptop Repair | New York (NY) | River Edge, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRiverEdge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/river-edge/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | River Edge, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRiverEdge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/rockleigh/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Rockleigh, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRockleigh-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/rockleigh/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Rockleigh, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRockleigh-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/rutherford/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Rutherford, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairRutherford-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/rutherford/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Rutherford, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairRutherford-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/secaucus/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Secaucus, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairSecaucus-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/secaucus/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Secaucus, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairSecaucus-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/tenafly/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Tenafly, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairTenafly-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/tenafly/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Tenafly, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairTenafly-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/upper-saddle-river/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Upper Saddle River, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairUpperSaddleRiver-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/upper-saddle-river/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Upper Saddle River, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairUpperSaddleRiver-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/waldwick/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Waldwick, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairWaldwick-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/waldwick/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Waldwick, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairWaldwick-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/wallington/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Wallington, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairWallington-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/wallington/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Wallington, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairWallington-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/westwood/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Westwood, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairWestwood-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/westwood/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Westwood, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairWestwood-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/wood-ridge/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Wood-Ridge, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairWoodRidge-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/wood-ridge/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Wood-Ridge, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairWoodRidge-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/woodcliff-lake/new-jersey/en",
    name: "Laptop Repair | New York (NY) | Woodcliff Lake, New Jersey",
    component: () => import("./views/en/address/laptop-repair/NewJersey/LaptopRepairWoodcliffLake-NewJersey.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/woodcliff-lake/new-jersey/es",
    name: "Reparacion de Laptop | New York (NY) | Woodcliff Lake, New Jersey",
    component: () => import("./views/es/address/laptop-repair/NewJersey/LaptopRepairWoodcliffLake-NewJersey-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  // routes BRONX - Laptop Repair

  {
    path: "/laptop-repair/ny/riverdale/bronx/en",
    name: "Laptop Repair | New York (NY) | Steinway, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairRiverdale-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/riverdale/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Steinway, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairRiverdale-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/kingsbridge/bronx/en",
    name: "Laptop Repair | New York (NY) | Kingsbridge, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairKingsbridge-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/kingsbridge/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Kingsbridge, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairKingsbridge-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/berdford-park/bronx/en",
    name: "Laptop Repair | New York (NY) | Berdford Park, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairBerdfordPark-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/berdford-park/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Berdford Park, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairBerdfordPark-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/norwood/bronx/en",
    name: "Laptop Repair | New York (NY) | Norwood, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairNorwood-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/norwood/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Norwood, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairNorwood-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/university-heights/bronx/en",
    name: "Laptop Repair | New York (NY) | University heights, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairUniversityheights-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/university-heights/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | University heights, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairUniversityheights-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

   {
    path: "/laptop-repair/ny/morris-heights/bronx/en",
    name: "Laptop Repair | New York (NY) | Morris Heights, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairMorrisHeights-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/morris-heights/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Morris Heights, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairMorrisHeights-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/fordham/bronx/en",
    name: "Laptop Repair | New York (NY) | Fordham, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairFordham-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/fordham/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Fordham, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairFordham-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/parkchester/bronx/en",
    name: "Laptop Repair | New York (NY) | Parkchester, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairParkchester-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/parkchester/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Parkchester, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairParkchester-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/van-cortland-park/bronx/en",
    name: "Laptop Repair | New York (NY) | Van Cortland Park, BRONX",
    component: () => import("./views/en/address/laptop-repair/bronx/LaptopRepairVanCortlandPark-BRONX.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/van-cortland-park/bronx/es",
    name: "Reparacion de Laptop | New York (NY) | Van Cortland Park, BRONX",
    component: () => import("./views/es/address/laptop-repair/bronx/LaptopRepairVanCortlandPark-BRONX-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },


  
 // routes queens - Laptop Repair 
{
    path: "/laptop-repair/ny/ridgewood/queens/en",
    name: "Laptop Repair | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairRIDGEWOOD-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/ridgewood/queens/es",
    name: "Reparacion de Laptop | New York (NY) | RIDGEWOOD, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairRIDGEWOOD-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/glendale/queens/en",
    name: "Laptop Repair | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairGLENDALE-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/glendale/queens/es",
    name: "Reparacion de Laptop | New York (NY) | GLENDALE, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairGLENDALE-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },
  {
    path: "/laptop-repair/ny/maspeth/queens/en",
    name: "Laptop Repair | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairMASPETH-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/maspeth/queens/es",
    name: "Reparacion de Laptop | New York (NY) | MASPETH, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairMASPETH-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/laurel-hill/queens/en",
    name: "Laptop Repair | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairLaurelHill-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/laurel-hill/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Laurel Hill, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairLaurelHill-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/middle-village/queens/en",
    name: "Laptop Repair | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairMiddleVillage-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/middle-village/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Middle Village, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairMiddleVillage-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/blissville/queens/en",
    name: "Laptop Repair | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairBlissville-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/blissville/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Blissville, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairBlissville-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/corona/queens/en",
    name: "Laptop Repair | New York (NY) | Corona, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairCorona-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/corona/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Corona, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairCorona-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/east-elmhurst/queens/en",
    name: "Laptop Repair | New York (NY) | East Elmhurst, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairEastElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/east-elmhurst/queens/es",
    name: "Reparacion de Laptop | New York (NY) | East Elmhurst, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairEastElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/elmhurst/queens/en",
    name: "Laptop Repair | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairElmhurst-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/elmhurst/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Elmhurst, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairElmhurst-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/jackson-heights/queens/en",
    name: "Laptop Repair | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairJacksonHeights-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/jackson-heights/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Jackson Heights, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairJacksonHeights-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/woodside/queens/en",
    name: "Laptop Repair | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairWoodside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/woodside/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Woodside, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairWoodside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/sunnysude-gardens/queens/en",
    name: "Laptop Repair | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairSunnysudeGardens-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/sunnysude-gardens/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Sunnysude Gardens, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairSunnysudeGardens-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/sunnyside/queens/en",
    name: "Laptop Repair | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairSunnyside-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/sunnyside/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Sunnyside, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairSunnyside-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/long-island-city/queens/en",
    name: "Laptop Repair | New York (NY) | Long Island City, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairLongIslandCity-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/long-island-city/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Long Island City, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairLongIslandCity-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/hunters-point/queens/en",
    name: "Laptop Repair | New York (NY) | Hunters Point, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairHuntersPoint-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/hunters-point/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Hunters Point, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairHuntersPoint-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/astoria/queens/en",
    name: "Laptop Repair | New York (NY) | Astoria, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairAstoria-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/astoria/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Astoria, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairAstoria-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/steinway/queens/en",
    name: "Laptop Repair | New York (NY) | Steinway, QUEENS",
    component: () => import("./views/en/address/laptop-repair/queens/LaptopRepairSteinway-QUEENS.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/steinway/queens/es",
    name: "Reparacion de Laptop | New York (NY) | Steinway, QUEENS",
    component: () => import("./views/es/address/laptop-repair/queens/LaptopRepairSteinway-QUEENS-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

   // routes Brooklyn - Laptop Repair  

   {
    path: "/laptop-repair/ny/bedstuy/brooklyn/en",
    name: "Laptop Repair | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairBEDSTUY-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/bedstuy/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairBEDSTUY-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/williamsburg/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Williamsburg, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairWilliamsburg-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/williamsburg/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Williamsburg, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairWilliamsburg-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/dumbo/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Dumbo, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairDumbo-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/dumbo/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Dumbo, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairDumbo-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/bushwick/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Bushwick, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairBushwick-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/bushwick/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Bushwick, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairBushwick-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/greenpoint/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Greenpoint, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairGreenpoint-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/greenpoint/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Greenpoint, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairGreenpoint-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/fort-greene/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Fort Greene, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairFortGreene-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/fort-greene/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Fort Greene, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairFortGreene-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/east-flatbush/brooklyn/en",
    name: "Laptop Repair | New York (NY) | East Flatbush, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairEastFlatbush-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/east-flatbush/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | East Flatbush, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairEastFlatbush-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/park-slope/brooklyn/en",
    name: "Laptop Repair | New York (NY) | Park Slope, New York",
    component: () => import("./views/en/address/laptop-repair/Brooklyn/LaptopRepairParkSlope-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/park-slope/brooklyn/es",
    name: "Reparacion de Laptop | New York (NY) | Park Slope, New York",
    component: () => import("./views/es/address/laptop-repair/Brooklyn/LaptopRepairParkSlope-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },


   // routes Manhattan - Laptop Repair  

   {
    path: "/laptop-repair/ny/washington-heights/manhattan/en",
    name: "Laptop Repair | New York (NY) | Van Cortland Park, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairWashingtonHeights-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/washington-heights/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Van Cortland Park, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairWashingtonHeights-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/inwood/manhattan/en",
    name: "Laptop Repair | New York (NY) | Inwood, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairInwood-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/inwood/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Inwood, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairInwood-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/fort-george/manhattan/en",
    name: "Laptop Repair | New York (NY) | Fort George, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairFortGeorge-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/fort-george/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Fort George, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairFortGeorge-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/harlem/manhattan/en",
    name: "Laptop Repair | New York (NY) | Harlem, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairHarlem-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/harlem/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Harlem, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairHarlem-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/morninng-heights/manhattan/en",
    name: "Laptop Repair | New York (NY) | Morninng Heights, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairMorninngHeights-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/morninng-heights/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Morninng Heights, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairMorninngHeights-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/upper-west-side/manhattan/en",
    name: "Laptop Repair | New York (NY) | Upper West Side, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairUpperWestSide-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/upper-west-side/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Upper West Side, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairUpperWestSide-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/midtown-west/manhattan/en",
    name: "Laptop Repair | New York (NY) | Midtown West, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairMidtownWest-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/midtown-west/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Midtown West, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairMidtownWest-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  // {
  //   path: "/laptop-repair/ny/midtown-east/manhattan/en",
  //   name: "Laptop Repair | New York (NY) | Midtown East, Manhattan",
  //   component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairMidtownEast-Manhattan.vue"),
  //   meta: {
  //     contactformEn: true,
  //     menuEs: true,
  //     contactformEs: true,
  //     testimoniosusuaiosEs: true,
  //     piepaginaEs: true,
  //   },
  // },
  // {
  //   path: "/laptop-repair/ny/midtown-east/manhattan/es",
  //   name: "Reparacion de Laptop | New York (NY) | Midtown East, Manhattan",
  //   component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairMidtownEast-Manhattan-es.vue"),
  //   meta: {
  //     layout: "LayoutEs",
  //     menuEn: true,
  //     contactformEn: true,
  //     testimoniosusuaiosEn: true,
  //     piepaginaEn: true,
  //   },
  // },

  {
    path: "/laptop-repair/ny/garden-district/manhattan/en",
    name: "Laptop Repair | New York (NY) | Garden District, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairGardenDistrict-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/garden-district/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Garden District, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairGardenDistrict-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/murray-hill/manhattan/en",
    name: "Laptop Repair | New York (NY) | Murray Hill, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairMurrayHill-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/murray-hill/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Murray Hill, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairMurrayHill-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/west-village/manhattan/en",
    name: "Laptop Repair | New York (NY) | West Village, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairWestVillage-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/west-village/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | West Village, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairWestVillage-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/soho/manhattan/en",
    name: "Laptop Repair | New York (NY) | Soho, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairSoho-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/soho/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Soho, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairSoho-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/lower-east-side/manhattan/en",
    name: "Laptop Repair | New York (NY) | Lower East Side, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairLowerEastSide-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/lower-east-side/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Lower East Side, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairLowerEastSide-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/tribeca/manhattan/en",
    name: "Laptop Repair | New York (NY) | Tribeca, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairTribeca-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/tribeca/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Tribeca, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairTribeca-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/financial-district/manhattan/en",
    name: "Laptop Repair | New York (NY) | Financial District, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairFinancialDistrict-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/financial-district/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Financial District, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairFinancialDistrict-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/laptop-repair/ny/chinatown/manhattan/en",
    name: "Laptop Repair | New York (NY) | Chinatown, Manhattan",
    component: () => import("./views/en/address/laptop-repair/manhattan/LaptopRepairChinatown-Manhattan.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/laptop-repair/ny/chinatown/manhattan/es",
    name: "Reparacion de Laptop | New York (NY) | Chinatown, Manhattan",
    component: () => import("./views/es/address/laptop-repair/manhattan/LaptopRepairChinatown-Manhattan-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },





  // Brooklyn

  {
    path: "/webdesign/ny/bedstuy/brooklyn/en",
    name: "Web Design | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingBEDSTUY-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bedstuy/brooklyn/es",
    name: "Diseño Web | New York (NY) | BEDSTUY, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingBEDSTUY-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/williamsburg/brooklyn/en",
    name: "Web Design | New York (NY) | Williamsburg, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingWilliamsburg-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/williamsburg/brooklyn/es",
    name: "Diseño Web | New York (NY) | Williamsburg, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingWilliamsburg-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/dumbo/brooklyn/en",
    name: "Web Design | New York (NY) | Dumbo, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingDumbo-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/dumbo/brooklyn/es",
    name: "Diseño Web | New York (NY) | Dumbo, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingDumbo-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/bushwick/brooklyn/en",
    name: "Web Design | New York (NY) | Bushwick, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingBushwick-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/bushwick/brooklyn/es",
    name: "Diseño Web | New York (NY) | Bushwick, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingBushwick-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/greenpoint/brooklyn/en",
    name: "Web Design | New York (NY) | Greenpoint, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingGreenpoint-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/greenpoint/brooklyn/es",
    name: "Diseño Web | New York (NY) | Greenpoint, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingGreenpoint-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/fort-greene/brooklyn/en",
    name: "Web Design | New York (NY) | Fort Greene, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingFortGreene-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/fort-greene/brooklyn/es",
    name: "Diseño Web | New York (NY) | Fort Greene, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingFortGreene-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/east-flatbush/brooklyn/en",
    name: "Web Design | New York (NY) | East Flatbush, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingEastFlatbush-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/east-flatbush/brooklyn/es",
    name: "Diseño Web | New York (NY) | East Flatbush, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingEastFlatbush-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },

  {
    path: "/webdesign/ny/park-slope/brooklyn/en",
    name: "Web Design | New York (NY) | Park Slope, New York",
    component: () => import("./views/en/address/Brooklyn/WebDesingParkSlope-Brooklyn.vue"),
    meta: {
      contactformEn: true,
      menuEs: true,
      contactformEs: true,
      testimoniosusuaiosEs: true,
      piepaginaEs: true,
    },
  },
  {
    path: "/webdesign/ny/park-slope/brooklyn/es",
    name: "Diseño Web | New York (NY) | Park Slope, New York",
    component: () => import("./views/es/address/Brooklyn/WebDesingParkSlope-Brooklyn-es.vue"),
    meta: {
      layout: "LayoutEs",
      menuEn: true,
      contactformEn: true,
      testimoniosusuaiosEn: true,
      piepaginaEn: true,
    },
  },


  
 
  // laptop repair NewYork

  // {
  //   path: "/laptop-repair/ny/washington-heights/new-york/en",
  //   name: "Laptop Repair | New York (NY) | Washington Heights, New York",
  //   component: () => import("./views/en/address/laptop-repair/NewYork/LaptopRepairWashingtonHeights-NewYork.vue"),
  //   meta: {
  //     contactformEn: true,
  //     menuEs: true,
  //     contactformEs: false,
  //     testimoniosusuaiosEs: true,
  //     piepaginaEs: true,
  //   },
  // },
  // {
  //   path: "/laptop-repair/ny/washington-heights/new-york/es",
  //   name: "Reparacion de Laptop | New York (NY) | Washington Heights, New York",
  //   component: () => import("./views/es/address/laptop-repair/NewYork/LaptopRepairWashingtonHeights-NewYork-es.vue"),
  //   meta: {
  //     layout: "LayoutEs",
  //     menuEn: true,
  //     contactformEn: true,
  //     testimoniosusuaiosEn: true,
  //     piepaginaEn: true,
  //   },
  // },


  

  

  
  /* / added as of 10/18/22 */



  /*--------------END PARTE BACKKK----------------------------------------- */
  {
    path: "/:pathMatch(.*)*/",
    name: "404",
    component: HomeView,
    meta: {
      auth: true,
      title: "PSOFNTECH - 404",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // document.title = `${to.params.type}`;
  window.scroll({
    top: 0,
    behavior: "smooth",
  });
  next();
});

export default router;
