import { createApp } from "vue";
import App from "./App.vue";
// Rutas
import router from "./router";
// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
// Icons
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
// Tables.net
import "datatables.net-dt/css/jquery.dataTables.min.css";
library.add(fas);
dom.watch();
// SweetAlert
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// Drpzone
import DropZone from "dropzone-vue";
import "dropzone-vue/dist/dropzone-vue.common.css";
// editor de texto
import { quillEditor } from "vue3-quill";
//validate
import { plugin, defaultConfig } from "@formkit/vue";
import "@formkit/themes/genesis";
// head
import { createHead } from "@vueuse/head";
const head = createHead();
import VueGtag from 'vue-gtag';
//

const backend = (process.env.NODE_ENV === 'production') ? 'https://psofntech.com//backend/' : 'http://localhost:80/psofntech_dev/backend/'
const backendView = (process.env.NODE_ENV === 'production') ? 'https://psofntech.com//backendView/' : 'http://localhost:80/psofntech_dev/backendView/'
const domain = (process.env.NODE_ENV === 'production') ? 'https://psofntech.com//' : 'http://localhost:8080/'



const app = createApp(App)

app.use(VueGtag, {
    config: { id: 'G-W131ZVGE18' }  // Reemplaza 'G-XXXXXXXXXX' con tu propio ID de seguimiento
  }, router);

app.config.globalProperties.backend = backend
app.config.globalProperties.backendView = backendView
app.config.globalProperties.domain = domain

app.use(router)
app.use(quillEditor)
app.use(DropZone)
app.use(VueSweetalert2)
app.use(head)
app.component("font-awesome-icon", FontAwesomeIcon)
app.use(plugin, defaultConfig)
app.mount("#app");
